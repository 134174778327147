/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {
  jQuery.fn.reverse = [].reverse;

  var SCREEN_SM = 768;
  var SCREEN_MD = 992;
  var $window = $(window);

  var $secondaryMenu = $('#top-bar').find('ul.nav');
  var $primaryMenu = $('.banner-left').find('ul.nav');

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function () {
        /**
         * Add current search query do filter's search input
         */
        function putCurrentSearchQuery() {
          if ($('form.searchandfilter').length) {
            const urlParams = new URLSearchParams(window.location.search);
            const searchParam = urlParams.get('search');
            
            if (searchParam) {
              $('form.searchandfilter input[name="ofsearch"]').val(searchParam);
            }
          }
        }
        putCurrentSearchQuery();

        /**
         * Handle pop-up with social media buttons
         */
        function shareButton() {
          const shareButton = $('.share-button');
          const shareDialog = $('.share-dialog');

          if (shareButton.length) {
            shareButton.click(function (e) {
              e.preventDefault();
              if (navigator.share) { 
                navigator.share({
                  title: document.title,
                  url: window.location
                })
                .catch(console.error);
              } else {
                shareDialog.addClass('is-open');
              }
            });

            shareDialog.find('[class*="close"]').click(function() {
              shareDialog.removeClass('is-open');
            });

            shareDialog.find('[class*="copy"]').click(function () {
              shareDialog.find('input[class*="url"]').select();
              document.execCommand('copy');
            });
          }
        }
        shareButton();

        /**
         * Handle posts sorting
         */
        function sortBy() {
          const select = $('select[name="sortby"]');
          const searchParams = new URLSearchParams(window.location.search);

          if (select.length) {
            select.on('change', function () {
              searchParams.set('sortby', $(this).val());
              window.location.search = searchParams.toString();
            })
          }
        }
        sortBy();

        /**
         * Enable tooltips
         */
        function tooltips() {
          $('[data-toggle="tooltip"]').tooltip();
        }
        tooltips();

        /**
         *
         * Merge menus under SCREEN_SM
         *
         */
        function mergeMenus() {
          var screenWidth = $window.innerWidth();
          var $toMerge;

          if(screenWidth < SCREEN_SM) {
            $toMerge = $secondaryMenu.find( 'li.xs-merge' );

            if($toMerge.length) {
              $toMerge.reverse().each(function() {
                $(this).prependTo($primaryMenu);
              });
            }
          } else {
            $toMerge = $primaryMenu.find('li.xs-merge');

            if($toMerge.length) {
              $toMerge.reverse().each( function ()
              {
                $( this ).prependTo( $secondaryMenu );
              } );
            }
          }
        }

        function collapseFilters() {
          var screenWidth = $window.innerWidth();

          if (screenWidth < SCREEN_MD) {
            $('.sidebar-product .collapse').each(function() {
              var $this = $(this);

              if(!$this.hasClass('in')) {
                return;
              }

              $this.collapse('hide');
            });
          } else {
            $('.sidebar-product .collapse').each(function() {
              var $this = $(this);

              if($this.hasClass('in')) {
                return;
              }

              $this.collapse('show');
            });
          }

        }

        $window.on('resize', function() {
          mergeMenus();
          collapseFilters();
        });

        mergeMenus();

        /**
         *
         *  Initialize flexslider if page contains a slider with more than one slide.
         *
         */
        $('[data-slider]').each(function() {
          var $this = $(this);
          var $slides = $this.find('.slide');

          // Bail if there is less than two slides, or if flexslider is not loaded.
          if($slides.length < 2 || typeof($.fn.flexslider) === 'undefined') {
            return;
          }

          var sliderSettings = $this.data('slider');

          $(this).flexslider({
            animation: sliderSettings.animation,
            namespace: 'slider-',
            easing: 'swing',
            smoothHeight: false,
            randomize: sliderSettings.randomized,
            controlNav: false,
            directionNav: sliderSettings.navigationShown,
            selector: '.slides > .slide',
            slideshowSpeed: sliderSettings.speed,
            animationSpeed: sliderSettings.animationSpeed,
            pauseOnHover: sliderSettings.pausedOnHover,
            start: function(slider){
              slider.resize();
            }
          });
        });

        /**
         *
         *
         * Make shop widgets collapsible
         *
         */
        $('.sidebar-widgets section').each(function(index) {
          var $this = $(this);

          var $link = $(this).find('h3.filter a');
          var $content = $(this).find('.widget-content');

          var id = 'collapse_' + index;

          $content.attr('id', id);
          $content.addClass('collapse in');

          $link.attr('data-toggle', 'collapse');
          $link.attr('data-target', '#' + id);
        });

        collapseFilters();

        $(document).on('click', '.yamm .dropdown-menu', function(e) {
          e.stopPropagation();
        });

        /**
         *
         * Enable megamenu
         *
         * @type {string}
         */
        var navbarToggle = '.navbar-toggle'; // name of navbar toggle, BS3 = '.navbar-toggle', BS4 = '.navbar-toggler'
        $('.dropdown, .dropup').each(function() {
          var dropdown = $(this),
            dropdownToggle = $('[data-toggle="dropdown"]', dropdown),
            dropdownHoverAll = dropdownToggle.data('dropdown-hover-all') || false;
        });

        function needsAppending(href) {
          if(href.indexOf('tbx=1') !== -1) {
            return false;
          }

          if(href.substr(0, 1) !== '/') {
            return false;
          }

          if(href.indexOf('category/flessen') !== -1) {
            return true;
          }


          if(href.indexOf('shop') !== -1) {
            return true;
          }

          return false;
        }

        function appendTbx(href) {
          if(href.indexOf('?') !== -1) {
            return href + '&tbx=1';
          } else {
            return href + '?tbx=1';
          }
        }


        function addTbxToUrl() {
          if(window.location.href.indexOf('tbx=1') === -1) {
            return;
          }

          $('a').each(function() {
            var $a = $(this);

            var href = $a.attr('href');

            if(needsAppending(href)) {
              $a.attr('href', appendTbx(href));
            }
          });
        }

        addTbxToUrl();
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);
  
 //$("#sidebar-cocktails .content").toggle();
  
})(jQuery); // Fully reference jQuery after this point.
