(function ($) {
    var timeOut = null,
        $window = $(window),
        screenWidth = 0;

    function isOutOfBounds(screenWidth, min, max) {
        if(max && screenWidth > max) {
            return true;
        }

        if(min && screenWidth < min) {
            return true;
        }

        return false;
    }

    /** Functions **/
    function equalizeHeights() {
        var $parents = $("[data-equal-height]");
        screenWidth = $window.width();

        var processed = [];

        $parents.each(function () {
            var $this = $(this),
                max_height = 0,
                selector = $this.data('equal-height'),
                min = $this.data('min'),
                max = $this.data('max'),
                $elements = $this.find(selector),
                outOfBounds = isOutOfBounds(screenWidth, min, max),
                peh = $this.hasParentEqualHeights();

            if (!$elements.length) {
                return;
            }



            $elements.each(function() {
               var $element = $(this);

                if($element.hasClass('eq-processed') && outOfBounds && peh) {
                    return;
                }

                $element.css('min-height', '').addClass('eq-processed');
            });


            if(outOfBounds) {
                return;
            }

            $elements.each(function () {
                var height = $(this).outerHeight();

                if (max_height < height) {
                    max_height = height;
                }
                max_height++;
            });

            $elements.css('min-height', max_height + 'px');
        });

        $('.eq-processed').removeClass('.eq-processed');
    }

    function fireEqualize() {
        if(timeOut) {
            clearTimeout(timeOut);
        }

        timeOut = window.setTimeout(equalizeHeights, 100);
    }

    /** jQuery plugin **/
    $.fn.hasParentEqualHeights = function() {
        return $(this).closest("[data-equal-height]").length > 0;
    };

    /** Init **/
    window.setTimeout(equalizeHeights, 250);

    /** Events **/
    $(window).on('resize', fireEqualize);
    $(window).on('aln_reloaded', function() {
        window.setTimeout(equalizeHeights, 250);
    });

})(jQuery);